import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { FaGreaterThan } from 'react-icons/fa';
import ReactFullpage from '@fullpage/react-fullpage';
import { Link } from "gatsby";
// import Img from 'gatsby-image'

const anchors = [];

const NsCasesCollection = ({
  cases,
}) => (
    <ReactFullpage
      //fullpage options
      licenseKey={'YOUR_KEY_HERE'}
      anchors={anchors}
      scrollingSpeed={1000} /* Options here */
      navigation
      navigationTooltips={anchors}

      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <></>
            {cases.map(c => {
              return (
                <div className="section fullpage-slide-section" style={{ backgroundImage: `url(${c.casesImage.asset.url})` }}>
                  <div className="container">
                    <div className="row">
                      <div className="fullpage-slide">
                        <div className="h3 subtitle" style={{ color: c.secondaryColor }}>{c.subTitle}</div>
                        <div className="h1 title" style={{ color: c.primaryColor }}>{c.title}</div>
                        <div className="content" style={{ color: c.secondaryColor }}>
                          {c.blockContent && <BlockContent blocks={c.blockContent} />}
                        </div>
                        <a href={c.buttonUrl} target="_blank" rel="noopener noreferrer" data-slimstat="5" className="fullpage-slide-button" style={{ backgroundColor: c.primaryColor }}>
                          {c.buttonText + ' '}
                          <FaGreaterThan />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );

export default NsCasesCollection
